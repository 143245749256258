.filter-form {
  position: relative;
  min-height: 3.5rem;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.04);
  padding: 1rem 1rem 0 1rem;

  h4 {
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(10, 47, 82, 0.2);
  }

  form {
    flex-direction: column;
    display: flex;
  }

  &--button,
  &--export-button {
    position: absolute;
    top: -3.5rem;
    right: 1rem;

    &:hover {
      background: #0a2f52;
      color: white;
    }
  }

  &--export-button {
    right: 4rem;
  }

  .circle-button {
    background: #e6e9ed;
    border-radius: 50%;
    display: inline-block;

    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    text-align: center;
    line-height: 2.5rem;
    color: #0a2f52;
    cursor: pointer;
    flex: none;

    &--disabled {
      cursor: default;
    }

    &:hover,
    &--active {
      background: #0a2f52;
      color: white;
    }

    &__position,
    &__margin {
      margin: auto 1rem auto 0;
    }

    &__relative {
      top: -0.25em;
      position: relative;
    }

    &--position {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-underline {
      bottom: 0;
    }
  }

  .filter--save {
    float: right;
    cursor: pointer;
  }

  .filter--reset {
    float: right;
    cursor: pointer;
    margin-right: 1rem;
  }
}

.filter-form__item {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(10, 47, 82, 0.2);

  &--flex {
    display: flex;
    flex-wrap: wrap;
    min-height: 65px;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;

    button {
      max-height: 50px;
      margin-bottom: 1rem;
    }

    @media (max-width: 644px) {
      button {
        width: calc(100% - 40px - 1rem);
        margin-left: auto;
      }
    }
  }
}

.filter-form__operator {
  margin-right: 1rem;

  .mat-form-field {
    width: 160px;
    max-width: 100%;
  }

  @media (max-width: 644px) {
    margin-right: 0;

    .mat-form-field {
      width: calc(100% - 40px - 1rem);
    }

    .mat-form-field-wrapper {
      padding-bottom: 1rem;

      .mat-form-field-underline {
        bottom: 1rem;
      }
    }
  }
}

.filter-form__search {
  display: flex;
  flex-wrap: wrap;
}

.filter-form__filter {
  width: auto;
  max-width: 100%;

  .mat-form-field {
    width: 300px;
    max-width: 100%;
  }

  @media (max-width: 644px) {
    .mat-form-field {
      width: calc(100% - 40px - 1rem);
      margin-left: calc(40px + 1rem);
    }
  }
}

.filter-form__item--addfilter {
  margin-right: 1rem;

  .mat-form-field {
    width: 355px;
    max-width: 100%;
  }

  @media (max-width: 644px) {
    margin-right: 0;
    flex: 1;

    .mat-form-field {
      width: 100%;
    }

    .mat-form-field-wrapper {
      padding-bottom: 1rem;

      .mat-form-field-underline {
        bottom: 1rem;
      }
    }
  }
}

.filter-form__modal {
  position: fixed;
  background: #e6e9ed;
  display: flex;
  width: 100%;
  max-width: 30rem;
  margin: auto;
  padding: 1rem;
  top: 5rem;
  right: 5rem;
  z-index: 999999;
  justify-content: space-between;

  ppa-select {
    flex: 1;

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-underline {
        bottom: 0;
      }
    }
  }

  button {
    flex: 0 auto;
    margin: auto 0 auto 1rem;
  }
}

.filter-form__save--item,
.mat-raised-button.filter-form__save--item {
  margin-bottom: 1.25rem;
}

.filter-form--load {
  display: flex;
  margin-bottom: 1.25rem;

  ppa-select {
    flex: 1;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-underline {
      bottom: 0;
    }
  }

  .circle-button {
    margin: auto 0 auto 1rem;
  }
}
