.card {
  background-color: #fff;
  color: rgba($dark-primary, 0.5);
  padding: 0.75rem;
  border-radius: 0.75rem;
  margin-bottom: 0.75rem;
}

.card--elevate-1 {
  box-shadow: 1px 1px 4px rgba($dark-primary, 0.1);
}

.card__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 0.375rem;

    &[ng-reflect-color='primary'] {
      background-image: linear-gradient(to right, #70cb29, #39790f);
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;

    button {
      width: auto;
    }
  }
}

.card__heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.375rem;
  align-items: flex-start;

  &--border {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #f3f5f6;
  }

  &--column {
    display: block;

    @media (min-width: 800px) {
      display: inline-block;
    }
  }

  &--icon {
    margin: 0.25rem;

    @media (max-width: 799px) {
      i:before {
        transform: rotate(90deg);
      }
    }

    @media (min-width: 800px) {
      position: relative;
      bottom: 0.5rem;
      margin: 0 1rem;
    }
  }

  @media (min-width: 800px) {
    &--label {
      display: none;
    }
  }
}

h4.card__title {
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 0;
  vertical-align: middle;
}
.card__heading--column h4.card__title {
  display: block;
}

.card__right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.card__badge {
  background-color: rgba(10, 47, 82, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  font-size: 0.8rem;
  white-space: nowrap;

  i {
    margin-right: 0.35rem;
  }
}
