.container {
  display: flex;
  padding: 1rem;
}

.container__content {
  margin: 0 auto;
  max-width: 40rem;
  width: 100%;
}

.container__content--full {
  margin: 0 auto;
  width: 100%;
}

.container--bg-light {
  background-color: #ffffff;
}

.container--flex-direction-column {
  flex-direction: column;
}

.container--flex-full-height {
  min-height: calc(100vh - 2rem);
}

.container--flex-fill {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container--no-padding {
  padding: 0;
}

.container--desktop-overview {
  padding: 1rem;

  ppa-header {
    margin-bottom: 1rem;
  }

  ppa-filter-form {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-bottom: 0;
  }
}

.mobile .container--desktop-overview {
  padding: 2rem;
}
