$warn: map-get($styleguide-theme, warn);
$background: map-get($styleguide-theme, background);
$foreground: map-get($styleguide-theme, foreground);

.ng-select {
  .ng-select-container {
    color: mat-color($foreground, text);

    .ng-value-container {
      .ng-placeholder {
        color: mat-color($foreground, hint-text);
      }

      .ng-input {
        > input {
          color: mat-color($foreground, text);
        }
      }
    }
  }

  &.ng-select-opened {
    .ng-arrow-wrapper {
      .ng-arrow {
        border-color: transparent transparent mat-color($foreground, secondary-text);

        .mtx-select-invalid & {
          border-color: transparent transparent mat-color($warn);
        }
      }
    }
  }

  &.ng-select-disabled {
    .ng-value {
      color: mat-color($foreground, hint-text);
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          color: mat-color($foreground, text);
          background-color: mat-color($background, unselected-chip);

          &.ng-value-disabled {
            opacity: 0.4;
          }

          .ng-value-icon {
            &:hover {
              background-color: mat-color($foreground, divider);
            }
          }
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: mat-color($foreground, secondary-text);

    &:hover .ng-clear {
      color: mat-color($warn);
    }
  }

  .ng-arrow-wrapper {
    .ng-arrow {
      border-color: mat-color($foreground, secondary-text) transparent transparent;

      .mtx-select-invalid & {
        border-color: mat-color($warn) transparent transparent;
      }
    }
  }
}

.ng-dropdown-panel {
  background-color: mat-color($background, card);

  &.multiple {
    .ng-option {
      &.selected {
        background: mat-color($foreground, divider);
      }

      &.marked {
        background: mat-color($background, hover);
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid mat-color($foreground, divider);
  }

  .ng-dropdown-footer {
    border-top: 1px solid mat-color($foreground, divider);
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      color: mat-color($foreground, secondary-text);

      &.ng-option-marked {
        background: mat-color($background, hover);
      }

      &.ng-option-selected {
        background: mat-color($foreground, divider);
      }
    }

    .ng-option {
      color: mat-color($foreground, text);

      &.ng-option-marked {
        background: mat-color($background, hover);
      }

      &.ng-option-selected {
        background: mat-color($foreground, divider);
      }

      &.ng-option-disabled {
        color: mat-color($foreground, hint-text);
      }

      .ng-tag-label {
        color: mat-color($foreground, hint-text);
      }
    }
  }
}

.ng-select {
  &.ng-select-multiple {
    .ng-dropdown-panel-items {
      .ng-option {
        padding-left: calc(2rem + 12px);

        &::before {
          font-family: unicons-line;
          font-style: normal;
          font-weight: 400;
          speak: none;
          content: '';
          width: 20px;
          height: 20px;
          outline: 1px solid rgba(10, 47, 82, 0.2);
          background: rgba(10, 47, 82, 0.1);
          display: block;
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translateY(-10px);
          border-radius: 4px;
          text-align: center;
          line-height: 21px;
          font-size: 20px;
        }

        &.ng-option-selected {
          background: transparent;

          &::before {
            content: '\e9c3';
            color: #fff;
            outline: none;
            background-image: linear-gradient(to right, #70cb29, #39790f);
          }
        }

        &.ng-option-disabled {
          cursor: not-allowed;

          &::before {
            outline: 1px solid rgba(10, 47, 82, 0.05);
            background: rgba(10, 47, 82, 0.02);
          }
        }
      }
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  @media (max-width: 800px) {
    &.ng-select-opened {
      .ng-select-container {
        z-index: 99999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 3rem;
        padding: 0.5rem;
        background: #fff;

        &::before {
          content: '';
          position: fixed;
          width: 100vh;
          height: 100vh;
          background: #fff;
          top: -400px;
          left: 0;
          z-index: -1;
        }

        .ng-value-container {
          padding-left: 0.5rem;

          .ng-input > input {
            width: 85%;
          }
        }

        .ng-clear-wrapper {
          display: none;
        }

        .ng-arrow-wrapper {
          .ng-arrow {
            &::before {
              content: 'x';
              line-height: 1;
              font-size: 26px;
              font-family: Archivo, Helvetica Neue, sans-serif;
              font-weight: 400;
              letter-spacing: normal;
              position: relative;
              right: 5px;
            }

            border-width: 0;
          }
        }
      }

      &:not(.ng-select-multiple) {
        .ng-select-container {
          .ng-input {
            margin-left: 1rem;
          }
        }
      }

      &.ng-select-multiple {
        .ng-select-container.ng-has-value {
          .ng-value-container {
            .ng-placeholder {
              display: block;
            }

            .ng-value {
              display: none;
            }
          }
        }

        &.ng-select-filtered {
          .ng-select-container.ng-has-value {
            .ng-value-container {
              .ng-placeholder {
                display: none;
              }
            }
          }
        }
      }

      .ng-dropdown-panel {
        position: fixed;
        top: 3rem;
        left: 0;
        width: 100vw;
        height: 100vh;

        .ng-dropdown-panel-items {
          padding-bottom: 600px;
          height: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
