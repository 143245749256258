mat-radio-group {
  padding-bottom: 1.25em;
  display: block;
}

mat-radio-button {
  margin-right: 1em;

  div.mat-radio-inner-circle {
    background-image: linear-gradient(to right, #70cb29, #39790f);
  }

  div.mat-radio-outer-circle {
    background-color: rgba(10, 47, 82, 0.15);
    border: 0;
  }
}

mat-checkbox {
  .mat-checkbox-layout {
    background: rgba(10, 47, 82, 0.1);
    border-radius: 4px;
    width: 20px;
    height: 20px;

    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
      margin-right: 1rem;
    }

    .mat-checkbox-label {
      line-height: unset;
    }

    .mat-checkbox-frame {
      border-color: transparent;
    }
  }

  &.mat-checkbox-checked:not(.mat-checkbox-disabled) {
    .mat-checkbox-background {
      background-image: linear-gradient(to right, #70cb29, #39790f);
      width: 20px;
      height: 20px;
      border-radius: 4px;
    }

    .mat-checkbox-inner-container {
      height: 20px;
      width: 20px;
    }
  }

  &.mat-checkbox-background {
    background-color: rgba(10, 47, 82, 0.1);
  }
}

mat-slide-toggle {
  padding-bottom: 1.25em;
  font-weight: 700;

  .mat-slide-toggle-bar {
    height: 1.25em;
    border-radius: 0.625em;
    width: 2.25em;
    background-color: rgba(10, 47, 82, 0.1);
  }

  .mat-slide-toggle-thumb {
    background-color: white !important;
    margin-top: 0.25em;
    width: 1em;
    height: 1em;
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-image: linear-gradient(to right, #70cb29, #39790f);
    }

    .mat-slide-toggle-content {
      font-weight: 700;
      color: map-get($primary, 400);
    }

    &.mat-warn {
      .mat-slide-toggle-bar {
        background: #ff2e12;
      }

      .mat-slide-toggle-content {
        color: #ff2e12;
      }
    }
  }

  .mat-slide-toggle-thumb-container {
    left: 2px;
    top: -2px;
  }

  &.toggle-no-redirect {
    padding-bottom: 0px;
    height: 100%;

    @media (max-width: 500px) {
      .mat-slide-toggle-label {
        flex-direction: column;
        align-items: end;
      }

      .mat-slide-toggle-content {
        font-size: 0.75rem;
      }
    }
  }
}

form {
  width: 100%;
}

mat-form-field {
  width: 100%;
  display: block;

  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      border-radius: $spacer-xs;
      padding: $sizer-xs $sizer-sm 0 $sizer-sm;
    }

    .mat-form-field-infix {
      padding-bottom: $sizer-xs;
      width: 100%;
    }

    .mat-form-field-underline {
      border-radius: $spacer-xs;
      height: 1rem;
      overflow: hidden;

      &:before {
        background-color: $dark-primary-half-opacity;
      }
    }
  }

  &.mat-form-field-should-float {
    .mat-form-field-underline {
      &:before {
        background-color: $dark-primary;
      }
    }
  }

  &.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    width: 1.5em;
    height: 1.5em;
  }

  &.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 1em;
    height: 1.5em;
  }

  .mat-datepicker-toggle {
    color: $dark-primary;
  }
}

span.mat-option-text {
  color: $dark-primary-text;
}

.mat-select-arrow-wrapper {
  background-image: url('../images/angle-down.svg');
  background-size: 1.5rem;
}

.mat-select-arrow {
  opacity: 0;
}

.mat-form-field-ripple {
  display: none;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float,
.mat-input-server:focus + .mat-form-field-label-wrapper,
.mat-form-field-label {
  color: rgba($dark-primary-text, 0.5);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: rgba($dark-primary-text, 0.5);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba($dark-primary-text, 1);
}

input,
textarea,
[contenteditable] {
  caret-color: $dark-primary-text !important;
}

.form-info,
.form-error,
.form-warning {
  background-color: #ff2e12;
  border-radius: 0.5rem;
  padding: 1rem;
  color: white;
  font-size: 0.75rem;
  position: relative;
  margin-bottom: 1rem;

  &-push-away {
    margin-top: 1rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ff2e12;
  }
}

.form-warning--skip-before:before {
  display: none;
}

.form-warning {
  background-color: #ffb800;
  color: $dark-primary;

  &::before {
    border-bottom: 8px solid #ffb800;
  }
}

.form-info {
  background-color: #296399;

  &::before {
    border-bottom: 8px solid #296399;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-row--auto {
  gap: 2rem;
}

.flex-auto {
  flex: 1;
}

.flex-1-1 {
  flex-basis: 100%;
}

.flex-1-2 {
  flex-basis: 49%;
  min-width: 0;
}

.flex-1-3 {
  flex-basis: 32.3333333%;
  min-width: 0;
}

.flex-3-4 {
  flex-basis: 74%;
  min-width: 0;
}

.flex-1-4 {
  flex-basis: 24%;
  min-width: 0;
}

.flex-1-6 {
  flex-basis: 20%;
  min-width: 0;
}

.flex-2-3 {
  flex-basis: 65.6666666%;
  min-width: 0;
}

.ng-select-container,
.ng-dropdown-panel {
  z-index: 9;
}

.ng-clear-wrapper {
  margin-right: 0.25rem;
}

.ng-select.ng-select-disabled .ng-value {
  color: $dark-primary;
}

.form-error.form-error--push-down {
  margin-top: 1.5rem;
}

.amount__field {
  display: flex;

  .mat-form-field {
    flex: 1;
  }

  .amount__button {
    flex: 0 1 auto;
    margin-bottom: 1.34375em;
    margin-left: 10px;
    align-self: center;
    box-shadow: none;
  }
}
