.card-info {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #f3f5f6;
  font-size: 0.875rem;
}

.card-info__row {
  margin-bottom: 0.25rem;

  .warn {
    color: #ff2e12;
  }
  .primary {
    color: #70cb29;
  }
}
