@mixin primary-gradient-background {
  &.mat-primary,
  &[color='primary'],
  &[ng-reflect-color='primary'],
  &.mat-radio-inner-circle,
  &.mat-checkbox-inner-container {
    background-image: linear-gradient(to right, #70cb29, #39790f);
  }

  &.mat-warning,
  &[color='warning'],
  &[ng-reflect-color='warning'],
  &.mat-radio-inner-circle,
  &.mat-checkbox-inner-container {
    background-image: linear-gradient(to right, #cb2929, #790f0f);
  }

  &.circle-warning,
  &.circle-primary {
    color: #ffffff;
  }
}

@mixin basic-button {
  &:not([color]):not([ng-reflect-color]):not(a):not([disabled]):not(.mat-warn):not(.mat-primary) {
    background-color: rgba(10, 47, 82, 0.1);
  }
}

@mixin basic-button-link {
  & {
    background-color: rgba(10, 47, 82, 0.1);
  }
}

@mixin button-border-radius {
  &:not(.mat-fab):not(.mat-mini-fab) {
    border-radius: 12px !important;
  }
}

@mixin pre-icon {
  &[pre-icon] {
    padding-left: 0.8em;

    i {
      margin-right: 1em;
      float: left;
    }
  }
}

@mixin post-icon {
  &[post-icon] {
    padding-right: 0;

    i {
      margin-right: 0;
      margin-left: 0.8em;
    }
  }
}

@mixin fixed {
  &[fixed] {
    i {
      margin-right: 0;
    }
  }
}
