a.button,
button,
mat-button-toggle {
  &.mat-button-base {
    @include button-border-radius();
    @include pre-icon();
    @include post-icon();
    @include fixed();
    font-weight: 700;
    line-height: 28px;

    i {
      font-size: 1.4em;
    }

    &:not(.mat-fab):not(.mat-mini-fab) {
      padding-top: 0.4em !important;
      padding-bottom: 0.4em !important;
    }

    &:not([color]):not([ng-reflect-color]):not(a):not([disabled]):not(.mat-warn):not(.mat-primary) {
      background-color: inherit;
    }
  }

  &.mat-raised-button {
    @include primary-gradient-background();
    @include basic-button();
    line-height: 36px;
    border-radius: 16px !important;
  }

  &.mat-flat-button {
    @include primary-gradient-background();
    @include basic-button();
    color: $dark-primary-text;
    border-radius: 12px !important;
  }

  &.mat-fab {
    @include primary-gradient-background();
    @include basic-button();
  }

  &.mat-mini-fab {
    @include primary-gradient-background();
    @include basic-button();
  }

  &.mat-button-toggle {
    @include primary-gradient-background();
    @include basic-button();
    color: $dark-primary-text;
    border-radius: 12px !important;

    &.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
      border: 0;
      padding: 0.4em 16px;
      font-weight: 700;
      line-height: 28px;

      &.mat-warn, &.mat-primary {
        color: $light-primary-text;
      }

      span {
        padding: 0;
        line-height: 1;
      }
    }
  }

  &.mb-1 {
    margin-bottom: 1rem;
  }
}

a.button {
  @include basic-button-link();
}

a {
  &.mat-button,
  &.mat-raised-button,
  &.mat-stroked-button,
  &.mat-flat-button {
    color: #41a535;
  }
}

.button--full-width {
  width: 100%;
}
