.ppa-modal {
  box-sizing: border-box;
  background: white;
  position: fixed;
  border-radius: 1rem 1rem 0 0;
  padding: 1.5rem 1rem;
  z-index: 21;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  width: 100vw;
  max-width: 36rem;
  margin: 1.5rem auto 0 auto;

  @media (max-width: 36rem) {
    margin: 1.5rem 0 0 0;
  }
}

.ppa-modal--full-width {
  width: 90vw;
  max-width: 90vw;
}

@media (min-width: 400px) {
  .ppa-modal--full-width--desktop {
    width: 95vw;
    max-width: 95vw;
    margin-inline: auto;
  }
}

.ppa-modal--free-form {
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: unset;
  border-radius: 1rem;
}

.ppa-modal--nested {
  z-index: 31;
}

.ppa-modal--auto-scale-from-bottom {
  top: unset;
  max-height: 98vh;
}

.ppa-modal--confirm {
  padding: 2rem;
}

.ppa-modal--no-bottom-padding {
  padding-bottom: 0;
}

.ppa-modal__close-handler {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 1.5rem;
  margin-right: 1rem;
  color: $dark-primary;
  z-index: 21;
}

.ppa-modal__heading {
  padding-bottom: 0.5rem;

  p strong {
    color: #ff0000;
  }
}

.ppa-modal__content-flex {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.ppa-modal__content {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ppa-modal__content--fixed {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.ppa-modal__content--flex-direction-column {
  flex-direction: column;
}

.ppa-modal--no-bottom-padding {
  .ppa-modal__content {
    padding-bottom: 1rem;
  }
}

.ppa-modal__intro {
  color: $dark-primary-half-opacity;
}

.ppa-modal__actions {
  padding-top: 0.5rem;
  display: flex;
  button:first-child {
    margin-right: 0.5rem;
  }

  &--center {
    justify-content: center;
  }
}

.ppa-modal__fader {
  background: rgba($color: #000000, $alpha: 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 20;
}

.ppa-modal__fader--nested {
  z-index: 30;
}

.ppa-modal__wizard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ppa-modal__actions {
    justify-content: space-between;
  }
}

.ppa-modal__wizard--nested {
  z-index: 31;
}

.mat-calendar-body-selected {
  background-color: $dark-primary;
}

.background {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: #f3f5f6;
  z-index: -1;
}

.ppa-modal__buttons--sticky {
  padding-top: 1rem;
  background: #ffffff;
  position: sticky;
  bottom: 0;
}

.ppa-modal__partial--left {
  flex: 0;
}

.ppa-modal__partial--right {
  flex: 0;
}

.ppa-modal__partial--fill {
  flex: 1;
}

.ppa-modal__content--pre {
  white-space: pre;
}
