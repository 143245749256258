@for $i from 1 through 6 {
  h#{$i} {
    color: $dark-primary;
    margin: 0 0 0.5rem 0;
    font-weight: 700;
  }
  h#{$i}:not(.subheading-enabled) {
    margin: 0 0 1rem 0;
  }
  h#{$i}.m-0 {
    margin: 0;
  }
}

h1 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1em;
  font-weight: 400;
}

h4.subheading {
  color: $dark-primary-half-opacity;
  margin-bottom: 0;
}

h4.subheading--icon-green {
  i {
    color: #70cb29;
  }
}

.heading-centered {
  text-align: center;
}

.dummy-text {
  color: red;
}
