dl {
  display: flex;
  flex-direction: column;

  dt {
    color: $dark-primary;
    margin-bottom: 0.5rem;
    margin-right: 0.25rem;
  }

  dd {
    flex-basis: 50%;
    color: $dark-primary-half-opacity;
    margin-left: 0;
    flex-grow: 1;
    margin-bottom: 0.5rem;
  }

  .unknown-value {
    color: red;
  }

  .exclamation {
    color: #ffb800;
  }
}

@media (min-width: 800px) {
  .details {
    dl {
      display: grid;
      grid-template-columns: max-content auto;
    }

    dt {
      grid-column-start: 1;
    }

    dd {
      grid-column-start: 2;
    }

    .icon-green {
      color: #70CB29;
    }
    .icon-blue {
      color: #2199E8;
    }
  }
}
