.ngx-datatable {
  .invoice-type--debit {
    color: green;
  }

  .invoice-type--credit {
    color: red;
  }

  .invoice-type--settlement {
    color: blue;
  }
}
