// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
  $font-family: 'Archivo, Helvetica Neue, sans-serif',
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500),
);

@include mat-core($custom-typography);

@include angular-material-typography($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// Set Angular Material NgSelect theme

$dark-primary-text: #0a2f52;

$primary: (
  200: #70cb29,
  400: #41a535,
  600: #39790f,
  contrast: (
    200: $light-primary-text,
    400: $light-primary-text,
    600: $light-primary-text,
  ),
);

$accent: (
  200: #2199e8,
  600: #296399,
  contrast: (
    200: $dark-primary-text,
    600: $light-primary-text,
  ),
);

$warning: (
  100: #ff2e12,
  contrast: (
    100: $light-primary-text,
  ),
);

$styleguide-primary: mat-palette($primary, 200, 400, 600);

$styleguide-accent: mat-palette($accent, 200);

// The warn palette is optional (defaults to red).
$styleguide-warn: mat-palette($warning, 100);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$styleguide-theme: mat-light-theme(
  (
    color: (
      primary: $styleguide-primary,
      accent: $styleguide-accent,
      warn: $styleguide-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($styleguide-theme);
