/* You can add global styles to this file, and also import other style files */
html {
  touch-action: manipulation;
}

//body {
//  background-color: #f3f5f6;
//}
//
//@media (min-width: 500px) {
//  .container {
//    padding: 2rem;
//  }
//}

.form-section {
  margin-bottom: 2rem;

  &-button {
    display: flex;
    justify-content: space-between;
  }
}

.ppa-card-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.password-form {
  .mat-form-field-subscript-wrapper {
    z-index: 1;
  }

  .password-strength {
    &__wrapper {
      margin-top: -1.4rem;
      margin-bottom: 1.4rem;

      @media (max-width: 500px) {
        .mat-card-content {
          font-size: 13px;
        }
      }

      @media (max-width: 420px) {
        .mat-card-content {
          font-size: 11px;
        }
      }
    }
  }
}

ppa-action-card {
  .relation-company {
    font-weight: bold;
  }

  .relation-address,
  .relation-label,
  .relation-placeholder,
  .location-placeholder,
  .order-placeholder {
    color: rgba(10, 47, 82, 0.5);
  }

  .relation-label {
    font-size: 12px;
    margin-bottom: 0.2rem;
  }

  .action-card.primary {
    background-image: linear-gradient(to right, #70cb29, #39790f);
    color: #ffffff;

    .order-placeholder {
      color: #ffffff;
    }
  }
}

.action-cards--mb {
  margin-bottom: 1.5rem;
}

.action-card__content--secondary {
  opacity: 0.5;
}

.history-small {
  ppa-order-card {
    &:not(.history-card-dummy) {
      &:before {
        left: 75px !important;
      }

      &:after {
        left: 71px !important;
      }
    }

    & > .history-card {
      height: 20px;
      line-height: 20px;

      .pre-icon {
        font-size: 20px;
      }

      .title .status,
      .container.history,
      .phone-action,
      .order__detail-action {
        display: none;
      }
    }
  }
}

button.mapboxgl-ctrl-geolocate {
  display: none;
}

.card-dates {
  display: flex;
  padding: 1rem 0 0 1rem;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  justify-content: space-between;

  &--no-padding {
    padding: 1rem 0 0 0;
  }

  .card-date {
    flex: 0 1 auto;
    display: grid;
    justify-content: space-around;

    @media (min-width: 500px) {
      display: flex;
    }

    &--label,
    &--date {
      flex: 1 1 auto;
    }
  }
}

input.error {
  color: red;
}

.weights--parsed {
  background: rgba(0, 0, 0, 0.04);
  color: red;
  padding: 0.5em 0.75em 1.325em 0.75em;
  overflow-x: auto;

  span {
    display: inline-block;
    padding: 0.1rem 0.3rem;
    color: #fff;
    border-radius: 4rem;
    font-size: 0.9em;
    cursor: default;
  }

  i {
    margin-right: 0.3rem;
  }

  .weight {
    background-color: rgba(0, 128, 0, 0.68);
  }

  .license {
    background-color: rgba(0, 0, 255, 0.68);
  }

  .skipped {
    background-color: rgba(255, 0, 0, 0.25);

    &:empty {
      display: none;
    }
  }
}

.ppa-card__content {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 1rem;
  gap: 2rem;

  .ppa-card__info {
    flex: 1;

    &--manual {
      opacity: 0.5;
      font-style: italic;
    }
  }

  .ppa-card__action {
    background: rgba(10, 47, 82, 0.1);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-self: center;
    flex: 0 0 2rem;

    i {
      align-self: center;
    }
  }
}

.clickable {
  cursor: pointer;
}

.marker--dossier {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 3px solid #296399;
  border-radius: 50%;
}

.marker--route {
  width: 5px;
  height: 5px;
  background: #ffffff;
  border: 3px solid var(--route-color, #296399);
  border-radius: 50%;
}

@media (min-width: 500px) {
  .invoice-remarks {
    mat-form-field {
      .mat-form-field-wrapper {
        display: flex;
        height: 100%;
      }
    }
  }
}
