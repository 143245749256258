// Spacers
$spacer-xs: 0.25rem;
$spacer-sm: 0.5rem;
$spacer-md: 1rem;
$spacer-lg: 1.5rem;
$spacer-xl: 2rem;

// Sizers
$sizer-xs: 0.5em;
$sizer-sm: 0.75em;
$sizer-md: 1em;
$sizer-lg: 1.25em;
$sizer-xl: 1.5em;

// Colors
$dark-primary: $dark-primary-text;
$dark-primary-half-opacity: rgba($dark-primary-text, 0.5);
